import React, { useEffect, useState } from 'react';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import { graphql, useStaticQuery } from 'gatsby';
import axios from 'axios';
import { navigate } from 'gatsby-link';
import { URL_API } from 'gatsby-env-variables';
import './styles.css';
import LayoutPrivate from '@components/layouts/LayoutPrivate';
import { useAuth } from '@app/contexts/MainAppContext';

const PricingGuidePage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        pricing_guide_bg: file(relativePath: { eq: "paid-account-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const bg_pricing_guide = getImage(data.pricing_guide_bg);
  const pricingGuideBg = convertToBgImage(bg_pricing_guide);
  const [currencyCode, setCurrencyCode] = useState('');
  const [annualPrice, setYearly] = useState();
  const [monthlyPrice, setMonthlyPrice] = useState();
  const [lifetimePrice, setLifetimePrice] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState();

  const getData = async () => {
    const detectCurrency = await axios.get(`${URL_API}/pricing`);
    setCurrencyCode(detectCurrency.data.data.pricing.currency_symbol);
    setMonthlyPrice(detectCurrency.data.data.pricing.price_monthly);
    setYearly(detectCurrency.data.data.pricing.price_yearly);
    setLifetimePrice(detectCurrency.data.data.pricing.price_lifetime);
  };

  function setMonthly() {
    sessionStorage.setItem('subscriptionType', 'Monthly');
    sessionStorage.setItem('subscriptionAmount', monthlyPrice);
    navigate('/payment');
  }

  function setYearlys() {
    sessionStorage.setItem('subscriptionType', 'Yearly');
    sessionStorage.setItem('subscriptionAmount', annualPrice);
    navigate('/payment');
  }

  function setLifetime() {
    sessionStorage.setItem('subscriptionType', 'Lifetime');
    sessionStorage.setItem('subscriptionAmount', lifetimePrice);
    navigate('/payment');
  }

  useEffect(() => {
    getData();
  }, [subscriptionStatus]);

  return (
    <LayoutPrivate pageTitle="Pricing">
      <section className="bg-black w-full pl-5 px-5 xl:pb-40 pb-20 pt-36">
        <div className="rounded-3xl bg-white py-5 pt-14 grid grid-cols-12 max-w-screen-xl mx-auto">
          <div className="w-full col-span-12 pb-10">
            <h3 className="pricing-text font-rokkit font-bold mb-2 uppercase text-center xl:text-5xl text-3xl">Pricing Options</h3>
            <div className="mt-10 container xl:px-10 mx-auto flex flex-col md:flex-row">
              <div className="overflow-x-auto md:w-1/3">
                <table className="row table-auto">
                  <thead>
                    <tr>
                      <th className="font-opensans-bold px-4 py-2">
                        Montly
                        <button
                          onClick={setMonthly}
                          className="font-opensans full-width text-center pt-2 pb-2 text-white color-white uppercase">
                          Choose this Plan
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-opensans border px-4 py-2">
                        Unlimited access to stories as they are being released in real time
                      </td>
                    </tr>
                    <tr>
                      <td className="font-opensans border px-4 py-2">
                        Access to all previously written books, chapters, and installations
                      </td>
                    </tr>
                    <tr>
                      <td className="font-opensans border px-4 py-2">Private chat with the author</td>
                    </tr>
                    <tr>
                      <td className="text-center border px-4 py-2">
                        <StaticImage src={'../../images/close.svg'} width={20} height={20} className="" alt="close" />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center border px-4 py-2">
                        <StaticImage src={'../../images/close.svg'} width={20} height={20} className="" alt="close" />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center border px-4 py-2">
                        <StaticImage src={'../../images/close.svg'} width={20} height={20} className="" alt="close" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="overflow-x-auto md:w-1/3">
                <table className="row table-auto">
                  <thead>
                    <tr>
                      <th className="font-opensans-bold px-4 py-2">
                        <div className="pop-container">
                          Annual
                          <div className="font-opensans pop mt-1" style={{ opacity: 1 }}>
                            Popular
                          </div>
                        </div>
                        <button
                          onClick={setYearlys}
                          className="font-opensans full-width text-center pt-2 pb-2 text-white color-white uppercase">
                          Choose this Plan
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-opensans border px-4 py-2">
                        Unlimited access to stories as they are being released in real time
                      </td>
                    </tr>
                    <tr>
                      <td className="font-opensans border px-4 py-2">
                        Access to all previously written books, chapters, and installations
                      </td>
                    </tr>
                    <tr>
                      <td className="font-opensans border px-4 py-2">Private chat with the author</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Digital authograph from the author</td>
                    </tr>
                    <tr>
                      <td className="text-center border px-4 py-2">
                        <StaticImage src={'../../images/close.svg'} width={20} height={20} className="" alt="close" />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center border px-4 py-2">
                        <StaticImage src={'../../images/close.svg'} width={20} height={20} className="" alt="close" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="overflow-x-auto md:w-1/3">
                <table className="row table-auto">
                  <thead>
                    <tr>
                      <th className="font-opensans-bold px-4 py-2">
                        Lifetime
                        <button
                          onClick={setLifetime}
                          className="font-opensans full-width text-center pt-2 pb-2 text-white color-white uppercase">
                          Choose this Plan
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-opensans border px-4 py-2">
                        Unlimited access to stories as they are being released in real time
                      </td>
                    </tr>
                    <tr>
                      <td className="font-opensans border px-4 py-2">
                        Access to all previously written books, chapters, and installations
                      </td>
                    </tr>
                    <tr>
                      <td className="font-opensans border px-4 py-2">Private chat with the author</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Digital authograph from the author</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">
                        A physical copy of Bhat's previously published novel, we of the forsaken world, autographed and delivered to any
                        address in the world.
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">A digital autograph and epigraph to be dedicated to the subscriber</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LayoutPrivate>
  );
};

export default PricingGuidePage;
